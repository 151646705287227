export const WORDS = [
  'dumbo',
  'simba',
  'goofy',
  'bambi',
  'ariel',
  'belle',
  'alice',
  'jafar',
  'tramp',
  'baloo',
  'kanga',
  'hades',
  'wendy',
  'tiana',
  'mushu',
  'pongo',
  'kuzco',
  'basil',
  'moana',
  'tonka',
  'mulan',
  'holes',
  'annie',
  'walle',
  'brave',
  'luisa',
  'bruno',
  'pluto',
  'daisy',
  'dewey',
  'louie',
  'kronk',
  'pacha',
  'dopey',
  'happy',
  'magic',
  'fairy',
  'meeko',
  'percy',
  'flora',
  'fauna',
  'flynn',
  'robin',
  'marie',
  'kanga',
  'beast',
  'lefou',
  'mouse',
  'potts',
  'jumba',
  'timon',
  'endor',
]
